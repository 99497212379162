import React, { useState } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

export default function PDFViewer({ url }) {
  const [error, setError] = useState(false);

  // Initialize plugins
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  // Configure default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => defaultTabs.filter(tab => tab.id !== 'attachment'),
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots;
          return (
            <div className="rpv-toolbar">
              <div className="rpv-toolbar__left">
                <div className="rpv-toolbar__item">
                  <GoToPreviousPage />
                </div>
                <div className="rpv-toolbar__item">
                  <CurrentPageInput /> / <NumberOfPages />
                </div>
                <div className="rpv-toolbar__item">
                  <GoToNextPage />
                </div>
              </div>
              <div className="rpv-toolbar__center">
                <div className="rpv-toolbar__item">
                  <ZoomOut />
                </div>
                <div className="rpv-toolbar__item">
                  <Zoom />
                </div>
                <div className="rpv-toolbar__item">
                  <ZoomIn />
                </div>
              </div>
              <div className="rpv-toolbar__right">
                <div className="rpv-toolbar__item">
                  <ShowSearchPopover />
                </div>
                <div className="rpv-toolbar__item">
                  <EnterFullScreen />
                </div>
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
  });

  // Prevent text selection and copying
  React.useEffect(() => {
    const preventCopy = (e) => {
      e.preventDefault();
      return false;
    };

    const preventSelection = () => {
      return false;
    };

    document.addEventListener('contextmenu', preventCopy);
    document.addEventListener('copy', preventCopy);
    document.addEventListener('cut', preventCopy);
    document.addEventListener('selectstart', preventSelection);

    return () => {
      document.removeEventListener('contextmenu', preventCopy);
      document.removeEventListener('copy', preventCopy);
      document.removeEventListener('cut', preventCopy);
      document.removeEventListener('selectstart', preventSelection);
    };
  }, []);

  return (
    <div
      className="pdf-viewer-container h-[calc(100vh-100px)] w-full"
      style={{ userSelect: 'none', WebkitUserSelect: 'none' }}
    >
      {error ? (
        <div className="flex items-center justify-center h-full">
          <p className="text-red-500">Failed to load PDF document</p>
        </div>
      ) : (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className="h-full w-full">
            <Viewer
              fileUrl={url}
              plugins={[
                defaultLayoutPluginInstance,
                pageNavigationPlugin(),
                zoomPlugin(),
                fullScreenPluginInstance,
              ]}
              defaultScale={SpecialZoomLevel.PageFit}
              onError={(e) => {
                console.error(e);
                setError(true);
              }}
              theme={{
                theme: 'dark',
              }}
              renderError={(error) => {
                setError(true);
                return (
                  <div className="text-red-500">
                    Failed to load PDF document: {error.message}
                  </div>
                );
              }}
              renderTextLayer={false}
            />
          </div>
        </Worker>
      )}
    </div>
  );
}
