import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import {
  AiFillWechat,
  AiOutlineCode,
  AiOutlineCodeSandbox,
  AiOutlineCompass,
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineSchedule,
  AiFillAudio,
} from "react-icons/ai";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { FaHackerrank, FaBars } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import {
  HiOutlineSupport,
  HiOutlineUserGroup,
  HiOutlineVideoCamera,
} from "react-icons/hi";
import { IoBriefcaseOutline, IoDocumentTextOutline } from "react-icons/io5";
import {
  MdClose,
  MdEvent,
  MdOutlineAssessment,
  MdOutlineGroups,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { twMerge } from "tailwind-merge";
import { doSignOut } from "src/firebase";
import FirebaseContext from "src/firebase/context";
import { ImBook } from "react-icons/im";
import { AppSidebar } from "./app-sidebar";
import { DashboardSideBar } from "./DashBoardSideBar";

const Sidebar = forwardRef((props, ref) => {
  const { currentUser } = useContext(FirebaseContext);
  const { width, height } = useWindowSize();
  const isMobileScreen = width <= 768;
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const { pathname: path } = location;
  useImperativeHandle(ref, () => ({
    setOpen,
  }));
  if (currentUser.university.config.hide_sidebar) {
    return null;
  }

  return <DashboardSideBar ref={ref} />;
});

export default Sidebar;
